body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wheel-wrap{
  position: relative;
  /*overflow: hidden;*/
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}
.wheel-container {
  transition: transform 10s cubic-bezier(.25,-0.06,0,.98);
}
.pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10em;
  height: 10em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  background: #f9f9f9;
  z-index: 1;
}

.pointer:before {
  content: "";
  position: absolute;
  top: -2.3em;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  border: solid;
  border-width: 0 3em 5em 3em;
  border-color: transparent transparent #f9f9f9 transparent;
}

.pointer > .text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 4em;
  font-weight: bolder;
  color: #1c90ff;
  border-radius: 50%;
  z-index: 1;
  /*border: dashed rgba(28, 144, 255, .8) 4px*/
}
.wheel-segment{
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 80px 0 16px;
  box-sizing: border-box;
  height: 50px;
  left: 0;
  top: 50%;
  transform-origin: center
}

.wheel-segment-text{
  color: #fff;
  font-size: 1.6em;
  font-weight: 500;
  text-shadow: 0 1px 3px #666;
  text-align: left;
  padding: 1em;
}
.result{
  max-width: 200px;
  background: #eee;
  padding: 15px 35px;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
  color: #333;
}
.editBtn{
  position: absolute;
  top: 88px;
  right: 0;
}
.item{
  background: #f6f7f8;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  --semi-color-fill-0: #f6f7f8;
  border-radius: 8px;
}
.title{
  font-size: 36px;
  font-weight: 500;
  margin-top: 10px;
}
a{
    color: #1c90ff;
    text-decoration: none;
}

.result-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 120px;
}

/* 小于1000的屏幕*/
@media (max-width: 1800px) {
  .title{
    font-size: 20px;
    margin: 3px;
  }
  .result-wrap{
    height:80px;
  }
  .pointer{
    font-size: 8px;
  }
  .wheel-segment-text{
    font-size: 1.2em;
  }
  .pointer > .text {
    font-size: 22px !important;
  }
  .result{
    font-size: 18px;
  }
}
